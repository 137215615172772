import Cookies from 'js-cookie';

// Kiểm tra nếu localStorage khả dụng
const isLocalStorageAvailable = () => {
    try {
        const testKey = '__test__';
        localStorage.setItem(testKey, testKey);
        localStorage.removeItem(testKey);
        return true;
    } catch (error) {
        console.warn('localStorage not available, using sessionStorage or cookies.');
        return false;
    }
};

// Lựa chọn storage phù hợp (localStorage hoặc sessionStorage)
const storage = isLocalStorageAvailable() ? localStorage : sessionStorage;

const LOCAL_STORAGE_AUTH_KEY = "LOCAL_STORAGE_AUTH_BO_ADMIN";

// Lưu token vào storage
export const setToken = (token) => {
    try {
        storage.setItem(LOCAL_STORAGE_AUTH_KEY, token);
    } catch (error) {
        console.error('Error setting token in storage:', error);
    }
};

// Lấy token từ storage
export const getToken = () => {
    try {
        const token = storage.getItem(LOCAL_STORAGE_AUTH_KEY);
        return token || null;
    } catch (error) {
        console.error('Error getting token from storage:', error);
        return null;
    }
};

// Xóa token khỏi storage
export const removeToken = () => {
    try {
        storage.removeItem(LOCAL_STORAGE_AUTH_KEY);
    } catch (error) {
        console.error('Error removing token from storage:', error);
    }
};

// Fallback lưu trữ token bằng cookie nếu cần
export const setCookieToken = (token) => {
    Cookies.set(LOCAL_STORAGE_AUTH_KEY, token, { expires: 7 });
};

export const getCookieToken = () => Cookies.get(LOCAL_STORAGE_AUTH_KEY);
export const removeCookieToken = () => Cookies.remove(LOCAL_STORAGE_AUTH_KEY);
